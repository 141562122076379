export var partnerId = "3000030";
export var subPartnerId = "300003000";
export var uiConfId = "30030567";
export var kSessionVideoEntryId = "0_ak6726gk";
export var kSessionAudioEntryId = "0_j7zpaxfa";
export var streamPaymentsUrl = "https://bournemouthpayments.streamamg.com/account/register/step1";
export var streamAccountUrl = "https://bournemouthpayments.streamamg.com/account?&apisessionid=";
export var streamResetUrl = "https://bournemouthpayments.streamamg.com/account/reset/?lang=en";
export var streamRegisterUrl = "https://bournemouthpayments.streamamg.com/account/freeregistration";
export var liveVideoUrl = "/live";
export var liveAudioUrl = "/live?format=audio";
export var watchLiveLabel = "Watch Live";
export var listenLiveLabel = "Listen Live";
export var audioPassPurchasedLabel = "Audio Pass Purchased";
export var videoPassPurchasedLabel = "Match Pass Purchased";
export var subscribeLabel = "Subscribe to AFCBTV Live";
export var comingSoonLabel = "Coming Soon";
export var buyMatchPassLabel = "Buy Match Pass";
export var SHARE_PLATFORM_DETAILS = [{
  id: "facebook",
  shareUrl: "https://www.facebook.com/sharer/sharer.php?u="
}, {
  id: "twitter",
  shareUrl: "https://twitter.com/intent/tweet?url="
}, {
  id: "whatsapp",
  shareUrl: "whatsapp://send?text="
}, {
  id: "copylink",
  shareUrl: null
}];
export var metaConfigDescription = "Watch the Cherries live around the world with just a few clicks on the club’s official live streaming service.";