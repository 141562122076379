import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

//Private methods
var fallbackCopyTextToClipboard = function fallbackCopyTextToClipboard(text, cb) {
  var textArea = document.createElement("textarea");
  textArea.value = text; // Avoid scrolling to bottom

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
    cb();
  } catch (error) {
    throw Error(error);
  }

  document.body.removeChild(textArea);
};

var isIE = function isIE() {
  // eslint-disable-next-line
  return !!window.MSInputMethodContext && !!document.documentMode;
}; // Insert url param


var insertUrlParam = function insertUrlParam(key, value) {
  if (history.pushState) {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
    window.history.pushState({
      path: newurl
    }, "", newurl);
  }
}; // Get All url params


var getAllUrlParams = function getAllUrlParams(search) {
  var hashes = search.slice(search.indexOf("?") + 1).split("&");
  var params = {};
  hashes.map(function (hash) {
    var _hash$split = hash.split("="),
        _hash$split2 = _slicedToArray(_hash$split, 2),
        key = _hash$split2[0],
        val = _hash$split2[1];

    params[key] = decodeURIComponent(val);
  });
  return params;
}; // Get param value


var getUrlParamValue = function getUrlParamValue(key) {
  var search = Object.entries(getAllUrlParams(window.location.search)).filter(function (param) {
    return param[0].toLowerCase() === key.toLowerCase();
  });
  return search[0] ? search[0][1] : null;
}; //Script exists in head of document. Taken from: https://github.com/kaimallea/isMobile


var isMobile = function isMobile() {
  if (window.isMobile) {
    return window.isMobile.phone;
  } else {
    return false;
  }
};

var copyToClipBoard = function copyToClipBoard(text, cb) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, cb);
    return;
  }

  navigator.clipboard.writeText(text).then(function () {
    cb();
  }, function (error) {
    throw Error(error);
  });
};

var scrollIntoView = function scrollIntoView(elemToScrollTo, behaviour) {
  if (!isIE()) {
    elemToScrollTo.scrollIntoView({
      behavior: behaviour
    });
  }
};

export { insertUrlParam, getAllUrlParams, getUrlParamValue, isMobile, copyToClipBoard, scrollIntoView };