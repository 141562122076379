export var customKSessionCodesMap = {
  AUTHORISED: "AUTHORISED",
  UNAUTHORISED: "UNAUTHORISED",
  NO_SUBSCRIPTION: "NO_SUBSCRIPTION",
  ACCOUNT_BLOCKED: "ACCOUNT_BLOCKED",
  TOO_MANY_LOGINS: "TOO_MANY_LOGINS",
  UNKNOWN: "UNKNOWN"
};

var checkKSession = function checkKSession(data) {
  switch (data.Status) {
    case -1:
      console.log("Play media using the kSession : " + data.KSession);
      return {
        authorised: true,
        data: data.KSession,
        code: customKSessionCodesMap.AUTHORISED
      };

    case 0:
      console.log("The user is not logged in.");
      return {
        authorised: false,
        data: null,
        code: customKSessionCodesMap.UNAUTHORISED
      };

    case 1:
      console.log("The user has no active subscription.");
      return {
        authorised: true,
        data: null,
        code: customKSessionCodesMap.NO_SUBSCRIPTION
      };

    case 2:
      console.log("The user does not have sufficient entitlements.");
      return {
        authorised: true,
        data: null,
        code: customKSessionCodesMap.NO_SUBSCRIPTION
      };

    case 3:
      console.log("The user account is blocked by an administrator.");
      return {
        authorised: false,
        data: null,
        code: customKSessionCodesMap.ACCOUNT_BLOCKED
      };

    case 4:
      console.log("The user has failed concurrency check. 3 or more logins");
      return {
        authorised: true,
        data: null,
        code: customKSessionCodesMap.TOO_MANY_LOGINS
      };

    default:
      console.log("Access hasn’t been granted for another reason.");
      return {
        authorised: false,
        data: null,
        code: customKSessionCodesMap.UNKNOWN
      };
  }
};

export default checkKSession;