import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

var useGoogleAnalytics = function useGoogleAnalytics() {
  var location = useLocation();
  useEffect(function () {
    ReactGA.initialize("UA-33990425-1");
  }, []);
  useEffect(function () {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export default useGoogleAnalytics;