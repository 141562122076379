import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";

var useStreamAmgSdk = function useStreamAmgSdk() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isLoaded = _useState2[0],
      setIsLoaded = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      jqueryLoaded = _useState4[0],
      setJqueryLoaded = _useState4[1];

  useEffect(function () {
    /**
     * Needed to load jquery as there was a race case error when
     * SDK loads it and it fails.
     *
     * SDK checks to see if it's loaded, so it won't load twice.
     */
    var script = document.createElement("script");
    script.integrity = "sha256-wS9gmOZBqsqWxgIVgA8Y9WcQOa7PgSIX+rPA0VL2rbQ=";
    script.crossOrigin = "anonymous";
    script.src = "https://code.jquery.com/jquery-1.9.1.min.js";
    document.body.appendChild(script);

    script.onload = function () {
      return setJqueryLoaded(true);
    };
  }, []);
  useEffect(function () {
    // setup stream lbirary
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://bournemouthpayments.streamamg.com/_resx/js/stream-payments.js";
    document.body.appendChild(script);

    script.onload = function () {
      return setIsLoaded(true);
    };
  }, []);
  return {
    isLoaded: isLoaded && jqueryLoaded,
    streamPayments: isLoaded ? window.streamPayments : null
  };
};

export default useStreamAmgSdk;